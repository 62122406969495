export const auth = {
  login: `/login`,
  forgetPass: `/forget-pass`,
};

export const category = {
  category: `category`,
  categories: `categories`,
};
export const news = "/news";
export const createNews = "/news/create";

export const profile = "/profile";
export const changePassword = "/change-password";

export const user = {
  user: `/user`,
  regisgter: `/register`,
  users: `/user`,
};

export const mail = {
  mail: `/mail`,
};

export const inboxs = "/inboxs";

export const product = {
  product: `/product`,
  products: `/products`,
};

export const partnerOrg = {
  partnerOrg: `/partnerOrg`,
  partnerOrgs: `/partnerOrgs`,
};

export const generalInfo = {
  generalInfo: `/generalInfo`,
  generalnfos: `/generalInfos`,
};

export const uploadPhoto = "/uploadPhoto";
