import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Login from "views/Auth/Login";
import "api/interceptor";
import AppLayout from "layout";
import { MyContextProvider } from "context/AppContext";

function App() {
  return (
    <MyContextProvider>
      <Router>
        <Routes>
          <Route path={"*"} element={<AppLayout />} />
          <Route path="login" element={<Login />} />
        </Routes>
      </Router>
    </MyContextProvider>
  );
}

export default App;
