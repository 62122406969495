import { Layout } from "antd";

const { Footer } = Layout;

const AppFooter = () => {
  return (
    <Footer style={{ textAlign: "center", fontWeight: "500" }}>
      <a target="_blank" rel="noreferrer" href="https://tanasoft.mn/">
        Optimal N Max
      </a>{" "}
      ©{new Date().getFullYear()}
    </Footer>
  );
};

export default AppFooter;
