import React, { Suspense } from "react";

import { Layout, theme, Spin } from "antd";
import routes from "config/router";
import { useRoutes } from "react-router-dom";

const { Content } = Layout;

const AppContent = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <Suspense fallback={<Spin />}>
      <Content
        style={{
          margin: "24px 16px 0",
          padding: "10px",

          background: colorBgContainer,
        }}
      >
        {useRoutes(routes)}
      </Content>
    </Suspense>
  );
};

export default AppContent;
