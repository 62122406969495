import { user } from "api/endpoints";
import { importView } from "../utils/misc";

const AdminList = importView("Admin/List");

const adminRoutes = {
  children: [
    {
      path: user.users,
      element: <AdminList />,
    },
  ],
};

export default adminRoutes;
