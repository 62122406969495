import { DownOutlined } from "@ant-design/icons";
import { Col, Dropdown, Image, Row, Space } from "antd";
import ProfileMenu from "./ProfileMenu";
import profile from "image/profile.png";

const AppHeader = () => {
  const name = localStorage.getItem("name");

  return (
    <div>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "60px",
          paddingLeft: "16px",
          paddingRight: "16px",
          backgroundColor: "#fff",
        }}
      >
        <Col></Col>

        <Dropdown overlay={<ProfileMenu />} trigger={["click"]}>
          <Col>
            <Space>
              <Image preview={false} alt="" src={profile} height={30} width={30} style={{ marginRight: 5 }} />
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#000",
                }}
              >
                {name?.split(`"`)[1]}
              </span>
              <DownOutlined />
            </Space>
          </Col>
        </Dropdown>
      </Row>
    </div>
  );
};

export default AppHeader;
