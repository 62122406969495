import { createContext, useContext, useState } from "react";

export const MyContext = createContext();

export const MyContextProvider = ({ children }) => {
  const [data, setData] = useState();

  //   const MyContext = createContext();

  return <MyContext.Provider value={{ data, setData }}>{children}</MyContext.Provider>;
};

// export const App = () => useContext(MyContext)
