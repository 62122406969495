import axios from "axios";

const instance = axios.create({
  baseURL: "https://admin.optimal.mn/api/admin",
});

export const authRequest = (endpoint, payload) => {
  return instance.post(endpoint, payload, {
    headers: {
      Authorization: `Basic `,
    },
  });
};

export const getRequest = (endpoint, params = {}) => {
  return instance.get(endpoint, { params: params });
};

export const postRequest = (endpoint, payload, params = {}) => {
  return instance.post(endpoint, payload, { params });
};

export const putRequest = (endpoint, payload, params = {}) => {
  return instance.put(endpoint, payload, { params });
};

export const deleteRequest = (endpoint, params = {}) => {
  return instance.delete(endpoint, { params });
};

export default instance;
