import { useEffect, useState } from "react";
import { Button, Checkbox, Form, Image, Input, message, Row, Space } from "antd";
import { LockOutlined, UserOutlined, LoadingOutlined } from "@ant-design/icons";
import logoBlack from "assets/images/optimallogo.png";
import { authRequest } from "api";

import { Link, useNavigate } from "react-router-dom";
import { auth } from "api/endpoints";

const Login = () => {
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    // if (values.remember) {
    //   localStorage.setItem("email", values.email);
    //   localStorage.setItem("remember", values.remember);
    // } else {
    //   localStorage.removeItem("email");
    //   localStorage.removeItem("remember");
    // }
    const { userName, password } = values;

    await login(userName, password);
  };
  const login = async (userName, password) => {
    setLoading(true);

    try {
      const res = await authRequest(auth.login, {
        UserName: userName,
        Password: password,
      });
      localStorage.setItem("data", JSON.stringify(res.data.Token));
      localStorage.setItem("name", JSON.stringify(res.data.UserName));
      setLoading(false);
      navigate("/");
      message.success("Амжилттай нэвтэрлээ.");
    } catch (error) {
      setLoading(false);
      console.log("erer", error);
      message.error(error.error.message ?? "Алдаа гарлаа");
    }
  };

  useEffect(() => {
    const data = localStorage.getItem("data");

    if (data) {
      navigate("/");
    } else {
      form.resetFields();
      const name = localStorage.getItem("name");
      const remember = localStorage.getItem("remember");

      if (name && remember) {
        form.setFieldsValue({ userName: name });
        form.setFieldsValue({ remember: remember });
      }
    }
  }, []);

  return (
    <Row
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
      }}
    >
      <Row
        style={{
          padding: "30px",
          borderRadius: "16px",
          boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
        }}
      >
        <Form onFinish={onFinish} style={{ width: "300px" }} form={form}>
          <Form.Item style={{ textAlign: "center" }}>
            <Image src={logoBlack} style={{ width: "150px" }} preview={false} />
          </Form.Item>
          <Form.Item name="userName" hasFeedback rules={[{ required: true, message: "Нэвтрэх нэрээ оруулна уу!" }]}>
            <Input prefix={<UserOutlined />} placeholder="Нэвтрэх нэр" />
          </Form.Item>
          <Form.Item hasFeedback name="password" rules={[{ required: true, message: "Нууц үгээ оруулна уу!" }]}>
            <Input prefix={<LockOutlined />} type="password" placeholder="Нууц үг" />
          </Form.Item>
          <Space style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item name="remember" valuePropName="checked">
              <Checkbox>Намайг сана</Checkbox>
            </Form.Item>
            <Form.Item name="password-reset">
              <Link to="/forgot-password">Нууц үг сэргээх</Link>
            </Form.Item>
          </Space>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ width: "100%", height: "50px", borderRadius: "8px" }}
            >
              {loading ? <LoadingOutlined /> : <div>Нэвтрэх</div>}
            </Button>
          </Form.Item>
        </Form>
      </Row>
    </Row>
  );
};

export default Login;
