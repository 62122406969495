import { Image, Layout, Menu } from "antd";
import AppHeader from "./header";
import logoWhite from "assets/images/onm.png";
import React, { useEffect, useState } from "react";
import AppContent from "./content";
import AppFooter from "./footer";
import { useLocation, useNavigate } from "react-router-dom";
import AntdIcon from "components/AntdIcon";
import { menuList } from "./menuList";

const { Sider } = Layout;

function AppLayout() {
  const navigate = useNavigate();
  const location = useLocation();
  const checkUserLogin = () => {
    const user = localStorage.getItem("data");
    if (!user) {
      navigate("/login");
    }
  };

  useEffect(() => {
    checkUserLogin();
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    const user = localStorage.getItem("data");
    console.log("user", user);
    if (!user) {
      navigate("login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const items = menuList.map((el, index) => ({
    key: el.route,
    icon: AntdIcon(el.icon),
    label: <div>{el.name}</div>,
  }));

  const [collapsed, setCollapsed] = useState(false);
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider width={260} collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
        <div style={{ padding: "15px 0px", textAlign: "center" }}>
          <Image src={logoWhite} style={{ width: !collapsed ? "120px" : "50px" }} preview={false} />
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[location.pathname]}
          items={items}
          onSelect={({ key }) => navigate(key)}
        />
      </Sider>
      <Layout>
        <AppHeader />
        <AppContent />
        <AppFooter />
      </Layout>
    </Layout>
  );
}

export default AppLayout;
