import { news } from "api/endpoints";
import { importView } from "../utils/misc";

const NewsList = importView("News/List");
const NewsForm = importView("News/Form");

const newsRoutes = {
  path: "/",
  children: [
    {
      path: news,
      element: <NewsList />,
    },
    {
      path: `${news}/:_id`,
      element: <NewsForm />,
    },
  ],
};

export default newsRoutes;
