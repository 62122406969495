import { mail } from "api/endpoints";
import { importView } from "../utils/misc";
// import VerifList from "../views/Verif/List";

const VerifList = importView("Verif/List");

const adminRoutes = {
  children: [
    {
      path: mail.mail,
      element: <VerifList />,
    },
  ],
};

export default adminRoutes;
