import { category, generalInfo, inboxs, mail, news, partnerOrg, product, user } from "api/endpoints";

export const menuList = [
  {
    name: "Мэдээ",
    route: news,
    icon: "InboxOutlined",
  },
  {
    name: "Хэрэглэгч",
    route: user.users,
    icon: "UsergroupAddOutlined",
  },
  {
    name: "Хүсэлтүүд",
    route: mail.mail,
    icon: "MailOutlined",
  },
];
